<template>
    <Dialog v-model:visible="isOpen" :header="form.id ? 'Visualizar' : 'Incluir'" :style="{ width: '60%' }"
        @hide="close">
        <div v-if="!form.id" class="field">
            <label for="customer">Cliente</label>
            <DropdownCliente id="customer" v-model="form.customer" class="w-full"
                :class="{ 'p-invalid': v$.form.customer.$invalid && submitted }"
                :filtrosExtras="filtrosExtrasCustomer" />
            <small v-if="v$.form.customer.$invalid && submitted" class="p-error">
                {{ v$.form.customer.required.$message }}
            </small>
        </div>
        <div v-if="!form.id" class="field">
            <label for="payload">JSON</label>
            <Textarea id="payload" v-model="form.payloadIntegracao" rows="20" class="w-full"
                :class="{ 'p-invalid': v$.form.payloadIntegracao.$invalid && submitted }" />
            <small v-if="v$.form.payloadIntegracao.$invalid && submitted" class="p-error">
                {{ v$.form.payloadIntegracao.required.$message }}
            </small>
        </div>
        <div v-if="form.id">
            <TabsIntegracaoGupy :record="form" :style="{ height: '430px' }" />
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" :label="!form.id ? 'Cancelar' : 'Fechar'"
                :disabled="confirmLoading" @click="close" />
            <Button v-if="!form.id" label="Confirmar" icon="pi pi-check" :disabled="form.id" :loading="confirmLoading"
                @click="confirmar" />
        </template>
    </Dialog>
</template>

<script>
import { getClientBase } from '../../../services/http';
import { showError, showSuccess } from '../../../utils/Toast';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import TabsIntegracaoGupy from './TabsIntegracaoGupy';
import DropdownCliente from '../../clientes/components/DropdownCliente';

export default {
    emits: ['onSuccess'],
    components: {
        DropdownCliente,
        TabsIntegracaoGupy
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            isOpen: false,
            confirmLoading: false,
            submitted: false,
            service: null,
            headerCompanyName: null,
            filtrosExtrasCustomer: { ativo: true, apenasComIntegracaoGupy: true },
            form: {}
        };
    },
    validations() {
        return {
            form: {
                customer: { required: helpers.withMessage('Cliente é obrigatório', required) },
                payloadIntegracao: { required: helpers.withMessage('Informações da integração obrigatórias', required) }
            }
        };
    },
    watch: {
        'form.customer'(newValue) {
            this.headerCompanyName = newValue?.companyNameGupy;
        }
    },
    methods: {
        open(record) {
            if (!record) {
                this.isOpen = true;
                return;
            }

            this.isOpen = true;
            this.form = record;
        },
        close() {
            this.isOpen = false;
            this.form = {};
        },
        async confirmar() {
            try {
                this.confirmLoading = true;
                this.submitted = true;
                const result = await this.v$.form.$validate();

                if (!result) {
                    this.confirmLoading = false;
                    return;
                }

                const { data } = await getClientBase({
                    'x-company-name': this.headerCompanyName
                }).post('/v1/webservice/gupy/internal', JSON.parse(this.form.payloadIntegracao));

                await this.$emit('onSuccess');
                this.confirmLoading = false;
                this.submitted = false;
                this.close();
                if (data?.status === 'error') {
                    showError(this.$toast, { response: { data } });
                } else {
                    showSuccess(this.$toast, data.message);
                    this.close();
                    await this.$emit('onSuccess');
                }
            } catch (error) {
                this.confirmLoading = false;
                this.submitted = false;
                showError(this.$toast, error);
            }
        }
    }
};
</script>

<style></style>
