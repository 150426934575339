<template>
    <TabView>
        <TabPanel header="Dados do funcionário">
            <div class="grid">
                <div class="col-2">
                    <label for="codigoFuncionario">Código</label>
                    <InputText id="codigoFuncionario" class="w-full" v-model="form.funcionarioId" disabled />
                </div>
                <div class="col-2">
                    <label for="codigoAgendamento">Código Agendamento</label>
                    <InputText id="codigoAgendamento" class="w-full" v-model="form.agendamentoId" disabled />
                </div>
                <div class="col-8">
                    <label for="nomeFuncionario">Nome</label>
                    <InputText id="nomeFuncionario" class="w-full" v-model="form.nomeFuncionario" disabled />
                </div>
                <div class="col-4">
                    <label for="dataNascimento">Data Nascimento</label>
                    <InputText id="dataNascimento" class="w-full" v-model="form.dataNascimento" disabled />
                </div>
                <div class="col-4">
                    <label for="cpf">CPF</label>
                    <InputMask id="cpf" class="w-full" v-model="form.cpf" mask="999.999.999-99" :unmask="true" disabled />
                </div>
                <div class="col-4">
                    <label for="sexo">Sexo</label>
                    <Dropdown
                        id="sexo"
                        class="w-full"
                        v-model="form.sexo"
                        optionLabel="label"
                        optionValue="value"
                        :options="optionsGenero"
                        disabled
                    />
                </div>
                <div class="col-12">
                    <label for="email">E-mail</label>
                    <InputText id="email" class="w-full" v-model="form.email" disabled />
                </div>
                <div class="col-8">
                    <label for="endereco">Endereço</label>
                    <InputText id="endereco" class="w-full" v-model="form.endereco" disabled />
                </div>
                <div class="col-4">
                    <label for="cep">CEP</label>
                    <InputText id="cep" class="w-full" v-model="form.cep" disabled />
                </div>
                <div class="col-7">
                    <label for="cidade">Cidade</label>
                    <InputText id="cidade" class="w-full" v-model="form.cidade" disabled />
                </div>
                <div class="col-5">
                    <label for="uf">UF</label>
                    <InputText id="uf" class="w-full" v-model="form.uf" disabled />
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Dados do cliente">
            <div class="grid">
                <div class="col-8">
                    <label for="empresa">Cliente</label>
                    <InputText id="empresa" class="w-full" v-model="form.empresa" disabled />
                </div>
                <div class="col-4">
                    <label for="dataAdmissao">Data Admissão</label>
                    <InputText id="dataAdmissao" class="w-full" v-model="form.dataAdmissao" disabled />
                </div>
                <div class="col-4">
                    <label for="gupyId">Código Gupy</label>
                    <InputText id="gupyId" class="w-full" v-model="form.gupyId" disabled />
                </div>
                <div class="col-4">
                    <label for="unidadeId">Código Unidade</label>
                    <InputText id="unidadeId" class="w-full" v-model="form.unidadeId" disabled />
                </div>
                <div class="col-4">
                    <label for="setorId">Código Setor</label>
                    <InputText id="setorId" class="w-full" v-model="form.setorId" disabled />
                </div>
                <div class="col-4">
                    <label for="cargoId">Código Cargo</label>
                    <InputText id="cargoId" class="w-full" v-model="form.cargoId" disabled />
                </div>
            </div>
        </TabPanel>
    </TabView>
</template>

<script>
import SexEnum from '../../../enums/SexEnum';
import { showError } from '../../../utils/Toast';

export default {
    props: {
        record: {
            type: Object
        }
    },
    mounted() {
        this.setDados(this.record);
        this.sexOptions();
    },
    data() {
        return {
            form: {},
            optionsGenero: []
        };
    },
    methods: {
        setDados(record) {
            try {
                if (!record.funcionarioId || !record.funcionario) {
                    const payloadIntegracao = JSON.parse(this.record.payloadIntegracao);
                    this.form = {
                        empresa: payloadIntegracao?.companyName,
                        gupyId: payloadIntegracao?.data?.job?.id,
                        unidadeId: 
                            payloadIntegracao?.data?.job?.branch?.id 
                            ?? payloadIntegracao?.data?.job?.branch?.code,
                        unidadeNome: payloadIntegracao?.data?.job?.branch?.name,
                        cnpjUnidade: record?.cnpjUnidade,
                        setorId: 
                            payloadIntegracao?.data?.job?.department?.id 
                            ?? payloadIntegracao?.data?.job?.department?.code,
                        setorNome: payloadIntegracao?.data?.job?.department?.name,
                        cargoId: 
                            payloadIntegracao?.data?.job?.role?.id 
                            ?? payloadIntegracao?.data?.job?.role?.code,
                        cargoName: payloadIntegracao?.data?.job?.role?.name,
                        nomeFuncionario: 
                            `${payloadIntegracao?.data?.candidate?.name} ${payloadIntegracao?.data?.candidate?.lastName}`,
                        email: payloadIntegracao?.data?.candidate?.email,
                        cpf: payloadIntegracao?.data?.candidate.identificationDocument,
                        dataNascimento: this.$filters.formatDateOnly(payloadIntegracao?.data?.candidate?.birthdate),
                        cep: payloadIntegracao?.data?.candidate?.addressZipCode,
                        endereco: payloadIntegracao?.data?.candidate?.addressStreet,
                        enderecoNumero: payloadIntegracao?.data?.candidate?.addressNumber,
                        cidade: payloadIntegracao?.data?.candidate?.addressCity,
                        uf: payloadIntegracao?.data?.candidate?.addressStateShortName,
                        telefone: payloadIntegracao?.data?.candidate?.mobileNumber,
                        sexo: this.getGenero(payloadIntegracao?.data?.candidate?.gender),
                        pcd: payloadIntegracao?.data?.candidate?.disabilities,
                        usuarioCriador: payloadIntegracao?.data?.user?.email,
                        usuarioName: payloadIntegracao?.data?.user?.name,
                        dataAdmissao: this.$filters.formatDateOnly(payloadIntegracao?.data?.application?.hiringDate)
                    };

                    return;
                }

                this.form = {
                    empresa: record.cliente.name,
                    gupyId: record.gupyId,
                    unidadeId: record.funcionario.customerHierarchy.customerBranch.codigoExterno,
                    cnpjUnidade: record.cnpjUnidade,
                    setorId: record.funcionario.customerHierarchy.customerDepartment.codigoExterno,
                    cargoId:record.funcionario.customerHierarchy.customerPosition.codigoExterno,
                    funcionarioId: record.funcionarioId,
                    nomeFuncionario: record.funcionario.name,
                    agendamentoId: record.agendamentoId,
                    email: record.funcionario.email,
                    cpf: record.funcionario.cpf,
                    dataNascimento: this.$filters.formatDateOnly(record.funcionario.dataNascimento),
                    cep: record.funcionario.zipCode,
                    endereco: record.funcionario.address,
                    cidade: record.funcionario.city,
                    uf: record.funcionario.state,
                    telefone: record.funcionario.phone,
                    sexo: this.getGenero(record.funcionario.sex),
                    dataAdmissao: this.$filters.formatDateOnly(record.funcionario.admissionDate)
                };
            } catch (error) {
                showError(this.$toast, error, 'Problemas ao formatar dados.');
            }
        },
        sexOptions() {
            this.optionsGenero = Object.keys(SexEnum).map(function (type) {
                return { label: `${SexEnum[type]}`, value: `${type}` };
            });
        },
        getGenero(genero) {
            switch (genero) {
                case 'Male':
                    return 'M';
                case 'Female':
                    return 'F';
                default:
                    return genero;
            }
        }
    }
};
</script>

<style></style>
